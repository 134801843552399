import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductos } from '../redux/actions';
import Carousel from 'react-bootstrap/Carousel';
import carrusel3 from "../assets/Mendoza3.jpg";

function DarkVariantExample() {
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.Productos || {});
  const idioma = useSelector(state => state.idioma);

  useEffect(() => {
    dispatch(getProductos());
  }, [dispatch]);

  // Debugging: Log the structure of allProducts
  console.log('All products:', allProducts);

  // Check if allProducts is an object
  if (typeof allProducts !== 'object' || allProducts === null) {
    console.error('allProducts is not an object');
    return null; // or render a fallback UI
  }

  // Check if experiecias property exists and is an array
  if (!Array.isArray(allProducts.experiecias)) {
    console.error('experiecias is not an array');
    return null; // or render a fallback UI
  }

  // Filtrar solo los productos con experiencias
  const filteredExperiences = allProducts.experiecias.filter(product => product);

  console.log('Filtered experiences:', filteredExperiences);

  return (
    <div>
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img 
            className="d-block w-100" 
            src={carrusel3} 
            alt="First slide" 
          />
          {filteredExperiences.map((product) => (
            <>
              <div className="card">
                <Carousel.Caption className='text-white'>
                  <h1 className='fuente bg-success text-white bg-opacity-50'>
                    {idioma === 'es' && product.textocarruseles ||
                     idioma === 'en' && product.textocarruselin ||
                     idioma === 'por' && product.textocarruselpor}
                  </h1>
                </Carousel.Caption>
              </div>
            </>
          ))}
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default DarkVariantExample;



// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getProductos } from '../redux/actions';
// import Carousel from 'react-bootstrap/Carousel';
// import carrusel3 from "../assets/Mendoza3.jpg";
// import { connect } from 'react-redux';

// function DarkVariantExample({ idioma }) {
//   const dispatch = useDispatch();
//   const allProducts = useSelector((state) => state.Productos || []);

//   useEffect(() => {
//     dispatch(getProductos());
//   }, [dispatch]);

//   console.log(allProducts.experiecias);

//   return (
//     <div>
      
//       <Carousel data-bs-theme="dark">
//         <Carousel.Item>
//           <img
//             className="w-100"
//             src={carrusel3}
//             alt="First slide"
//             width="100px" height="660px"
//           />
//           {allProducts.experiecias.map((product) => (
//             <>
//               <div class="card" key={product.id}>
//                 <Carousel.Caption className='text-white'>
//                   <h1 className='fuente bg-success text-white bg-opacity-50'>
//                     {idioma === 'es' && product.experiecias.textocarruseles}
//                     {idioma === 'en' && product.experiecias.textocarruselin}
//                     {idioma === 'por' && product.experiecias.textocarruselpor}
//                   </h1>
//                 </Carousel.Caption>
//               </div>
//             </>
//           ))}
//         </Carousel.Item>
//       </Carousel>
//     </div>
//   );
// }

// const mapStateToProps = (state) => ({
//   idioma: state.idioma,
// });

// export default connect(mapStateToProps)(DarkVariantExample);;



